import classnames from 'classnames';

import BpkLink from '@skyscanner/backpack-web/bpk-component-link';

import { ACTION_TYPE, COMPONENT_NAME, COMPONENT_ACTION } from '../../constants';
import logMiniEventHelper from '../../mini-event/logMiniEventHelper';

import type {
  LinkSectionProps,
  LinkSectionLink,
} from 'common-types/types/InternalLinks';

import STYLES from './InternalLinksV2.module.scss';

const onClick = (link: LinkSectionLink) => {
  logMiniEventHelper({
    action_type: ACTION_TYPE.COMPONENT_CLICKED,
    component_name: COMPONENT_NAME.INTERNAL_LINKS,
    component_action: COMPONENT_ACTION.INTERNAL_LINKS.INTERNAL_LINK_CLICKED,
    internal_link: {
      url: link.href,
      name: link.label,
    },
  });
};
const LinkSection = ({ active = false, links = [], tag }: LinkSectionProps) =>
  tag && links?.length ? (
    <div
      id={`link-section-${tag.replace(/\s+/g, '-')}`}
      className={classnames({
        [STYLES.InternalLinksV2__linksOfActive]: active,
        [STYLES.InternalLinksV2__linksOfInactive]: !active,
      })}
    >
      {links.map((link) => (
        <BpkLink key={link.href} href={link.href} onClick={() => onClick(link)}>
          {link.label}
        </BpkLink>
      ))}
    </div>
  ) : null;

export default LinkSection;
