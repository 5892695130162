import { useState, useEffect } from 'react';

import loadable from '@loadable/component';

import BpkSelectableChip, {
  CHIP_TYPES,
} from '@skyscanner/backpack-web/bpk-component-chip';
import BpkMobileScrollContainer from '@skyscanner/backpack-web/bpk-component-mobile-scroll-container';
import BpkSectionHeader from '@skyscanner/backpack-web/bpk-component-section-header';

import { ACTION_TYPE, LOAD_STATUS, COMPONENT_NAME } from '../../constants';
import logMiniEventHelper from '../../mini-event/logMiniEventHelper';

import LinkSection from './LinkSection';

import type { InternalLinksProps } from 'common-types/types/InternalLinks';

import STYLES from './InternalLinksV2.module.scss';

const IntersectionObserverWrapper = loadable(
  () => import('../IntersectionObserverWrapper/IntersectionObserverWrapper'),
);

const InternalLinksV2 = (props: InternalLinksProps) => {
  const [activeGroupIndex, setActiveGroupIndex] = useState(0);
  const { sections, subTitle, title } = props;

  const onToggleGroup = (index: number) => {
    if (index !== activeGroupIndex && index >= 0) {
      setActiveGroupIndex(index);
    }
  };

  useEffect(() => {
    logMiniEventHelper({
      action_type: ACTION_TYPE.COMPONENT_LOADED,
      component_name: COMPONENT_NAME.INTERNAL_LINKS,
      load_status:
        !title || !sections?.length ? LOAD_STATUS.INVALID : LOAD_STATUS.LOADED,
    });
  }, [title, sections]);

  if (!title || !sections?.length) {
    return null;
  }

  return (
    <div className={STYLES.InternalLinksV2}>
      <BpkSectionHeader title={title} description={subTitle} />

      <div
        className={
          sections.length > 1
            ? STYLES.InternalLinksV2__groupTabs
            : STYLES.InternalLinksV2__groupTabsHide
        }
      >
        <BpkMobileScrollContainer
          className={STYLES.InternalLinksV2__groupTabScroll}
          trailingIndicatorClassName={STYLES.Indicator}
        >
          {sections.map((section, index) => (
            <BpkSelectableChip
              onClick={() => onToggleGroup(index)}
              accessibilityLabel={section.tag}
              selected={index === activeGroupIndex}
              type={CHIP_TYPES.default}
              key={section.tag}
              data-index={index}
              aria-controls={`link-section-${section.tag.replace(/\s+/g, '-')}`}
              className={STYLES.InternalLinksV2__groupTabBase}
            >
              {section.tag}
            </BpkSelectableChip>
          ))}
        </BpkMobileScrollContainer>
      </div>
      {sections.map((section, index) => (
        <LinkSection
          active={index === activeGroupIndex}
          key={section.tag}
          {...section}
        />
      ))}
    </div>
  );
};

export default (props: InternalLinksProps) => (
  <IntersectionObserverWrapper
    onElementSeen={() =>
      logMiniEventHelper({
        action_type: ACTION_TYPE.COMPONENT_VISIBLE,
        component_name: COMPONENT_NAME.INTERNAL_LINKS,
      })
    }
  >
    <InternalLinksV2 {...props} />
  </IntersectionObserverWrapper>
);
